import React, { useState, useEffect, useRef } from "react";
import { Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import axios from "axios";
import ProjectListView from "./ProjectList/ProjectListView";
import DeskView from "./Project/ProjectViews/Desk/DeskView";
import HistogramView from "./Project/ProjectViews/Histogram/HistogramView";
import MessagesView from "./Messages/MessagesView";
import SettingsView from "./Project/Settings/SettingsView";
import ProfileView from "./Profile/ProfileView";
import AcceptProjectInvite from "./ProjectList/AcceptProjectInvite";
import rest from "./http/axios";
import LeftMenu from "./LeftMenu";
import BottomMenu from "./BottomMenu";
import SubscribeProjectWithLink from "./Project/SubscribeProjectWithLink";

// SOCKET
import io from "socket.io-client";
import { HOST } from "./AppConfig";
// CONTEXT
import BasicContext from "./Context/BasicContext";

const AppRouter = (props) => {
  const { authorized } = props;
  const socket = useRef();

  const [messageNtfc, setMessageNtfc] = useState(0);
  const [changeNtfc, setChangeNtfc] = useState(0);
  // const [socket, setSocket] = useState();
  const [showSocketAlert, setShowSocketAlert] = useState(true);
  const [socketAlertInfo, setSocketAlertInfo] = useState("");
  const [background, setBackground] = useState("");

  // Переменные для работы с фокусировкой окна
  const [tabFocused, setTabFocused] = useState(false);

  const theme = localStorage.getItem("theme");

  let manualSocketRefreshTimeout = null; // таймаут для ручного обновления сокета, чтобы не обновлять его каждый раз при фокусировке окна

  // Function to refresh the access token
  async function refreshAccessToken() {
    try {
      const response = await axios.get(`${HOST}/api/refresh_token`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("refresh_token"),
        },
        withCredentials: true,
      });
      const access_token = response.data.access_token;
      return access_token;
    } catch (error) {
      console.log("Error refreshing access token:", error);
      throw error;
    }
  }
  // Function to connect the socket with the new access token
  function connectSocket(access_token) {
    console.log("Connecting socket");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    socket.current = io(HOST, { extraHeaders: headers, autoConnect: false });

    socket.current.on("connect", () => {
      console.log("Socket connected");
      setShowSocketAlert(false);
    });

    socket.current.on("disconnect", () => {
      setShowSocketAlert(true);
      console.log("Socket disconnected");
      handleSocketDisconnect(socket.current);
    });

    socket.current.on("error", (error) => {
      console.log("Socket error:", error);
    });

    socket.current.connect();
  }

  // Function to handle socket disconnection and reconnection
  function handleSocketDisconnect(oldSocket) {
    console.log("Ручная попытка активировать сокеты...");
    setSocketAlertInfo("Идет подключение...");

    refreshAccessToken()
      .then((access_token) => {
        setSocketAlertInfo("Ключ получен");
        oldSocket.close();
        connectSocket(access_token);
      })
      .catch((error) => {
        console.log("Error refreshing token during reconnection:", error);
      });
  }

  useEffect(() => {
    // Слушаем события фокусировки и разфокусировки окна
    window.addEventListener("focus", () => {
      setTabFocused(true);
    });
    window.addEventListener("blur", () => {
      setTabFocused(false);
    });

    refreshAccessToken()
      .then((access_token) => {
        connectSocket(access_token);
      })
      .catch((error) => {
        console.log("Error refreshing token:", error);
        // Handle error, e.g., redirect to login
      });

    const adoptThemeToOS = localStorage.getItem("adoptThemeToOS");

    if (adoptThemeToOS === "true") {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        localStorage.setItem("theme", "dark");
        const metaThemeColor = document.querySelector("meta[name=theme-color]");
        metaThemeColor.setAttribute("content", "#141414");
        setBackground("background_dark.jpg");
      } else {
        localStorage.setItem("theme", "");
        setBackground("background_light.jpg");
      }
    } else {
      localStorage.setItem("theme", "");
      setBackground("background_light.jpg");
    }

    return () => {
      if (socket.current) socket.current.close();
    };
  }, []);

  useEffect(() => {
    if (authorized === true) {
      // проверяю уведомления
      updateNotification();
      const timerId = setInterval(updateNotification, 10000);

      return () => {
        clearInterval(timerId);
      };
    }
  }, [authorized]);

  const updateNotification = () => {
    rest.get(`/load_notifications_update`).then((response) => {
      let rsp = response.data;
      if (rsp.messageNtfc > 0 || rsp.changeNtfc > 0) {
        setMessageNtfc(rsp.messageNtfc);
        setChangeNtfc(rsp.changeNtfc);
      } else {
        setMessageNtfc(0);
        setChangeNtfc(0);
      }
    });
  };

  // решаю проблему сломанного сокета в окнах, в которые давно не заходили
  // каждый раз при фокусировке окна проверяю, если сокет сломан, то вывожу алерт
  useEffect(() => {
    if (tabFocused == false) return;
    // проверяем, в первый раз, сломан ли сокет
    if (showSocketAlert == true) {
      manualSocketRefreshTimeout = setTimeout(() => {
        // если за 2 секунды сокет не подключился, то пытаемся переподключиться вручную
        if (showSocketAlert == true) {
          console.log("Попытка переподключения сокета после фокусировки");
          handleSocketDisconnect(socket.current);
        } else {
          clearTimeout(manualSocketRefreshTimeout);
          console.log("Сокет подключился самостоятельно");
        }
      }, 2000);
    } else {
      console.log("После активации вкладки сокет работает нормально");
    }

    return () => {
      clearTimeout(manualSocketRefreshTimeout);
    };
  }, [tabFocused]);

  return (
    <BasicContext.Provider
      value={{
        socket: socket.current,
        showSocketAlert: showSocketAlert,
        socketAlertInfo: socketAlertInfo,
        messageNtfc: messageNtfc,
        changeNtfc: changeNtfc,
        background: background,
      }}
    >
      <div className={`fullscreen ${theme}`}>
        <LeftMenu />
        <div className={`workspace-container`}>
          <Routes>
            {ProtectedRoute({
              path: ``,
              element: <ProjectListView />,
              authorized: authorized,
            })}

            {ProtectedRoute({
              path: `profile`,
              element: <ProfileView />,
              authorized: authorized,
            })}

            {ProtectedRoute({
              path: `messages`,
              element: <MessagesView />,
              authorized: authorized,
            })}

            {ProtectedRoute({
              path: `project/:projectId/settings`,
              element: <SettingsView />,
              authorized: authorized,
            })}

            {ProtectedRoute({
              path: `project/:projectId`,
              element: <DeskView />,
              authorized: authorized,
            })}

            {ProtectedRoute({
              path: `project/:projectId/task/:taskId`,
              element: <DeskView />,
              authorized: authorized,
            })}

            {ProtectedRoute({
              path: `project/:projectId/histogram`,
              element: <HistogramView />,
              authorized: authorized,
            })}

            {ProtectedRoute({
              path: `accept_project_invite/:token`,
              element: <AcceptProjectInvite />,
              authorized: authorized,
            })}

            {ProtectedRoute({
              path: `public_access/:accessType/:key`,
              element: <SubscribeProjectWithLink />,
              authorized: authorized,
            })}
          </Routes>
        </div>
        <BottomMenu theme={theme} />
      </div>
    </BasicContext.Provider>
  );
};
export default AppRouter;
