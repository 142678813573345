import React, { useState, useRef, useCallback, useEffect } from "react";
import rest from "../../../http/axios";
import "react-photo-view/dist/react-photo-view.css";
import { PhotoProvider, PhotoView } from "react-photo-view";

// ICONS
import CloseIcon from "@material-ui/icons/Close";

export default function Files(props) {
  const {
    files,
    addFileToState,
    deleteFile,
    changeWidgetSettingsEvent,
    theme,
  } = props;

  const [showWidgetNav, setShowWidgetNav] = useState(false);
  const [fileOverContainer, setFileOverContainer] = useState(false);

  const [images, setImages] = useState([]);

  const fileInputRef = useRef();
  const reImages = /.+(?:jpg|jpeg|gif|png)/i;

  useEffect(() => {
    const fetchImages = async () => {
      // Clear the images state initially
      setImages([]);

      // Filter out the files that match the regular expression
      const imageFiles = files.filter((item) => item.name.match(reImages));

      // Map the files to an array of promises
      const imagePromises = imageFiles.map(async (item) => {
        try {
          const response = await rest.get(`/view_image/${item.id}`, {
            responseType: "blob",
          });
          const imageUrl = URL.createObjectURL(response.data);
          item.imageUrl = imageUrl;
          return item;
        } catch (e) {
          console.log(e.response.status, e.response.data);
          return null;
        }
      });

      // Wait for all promises to resolve
      const images = await Promise.all(imagePromises);

      // Filter out any null values (failed requests)
      const validImages = images.filter((image) => image !== null);

      // Update the state with the fetched images
      setImages(validImages);
    };

    // Clear images if files array is empty and images array has items
    if (files.length === 0 && images.length > 0) {
      setImages([]);
    } else {
      fetchImages();
    }
  }, [files]);

  function addFileEvent(event) {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      addFileToState(files[i]);
    }
    fileInputRef.current.value = "";
  }

  function downloadFile(itemId) {
    let targetFile = props.files.filter((item) => item.id == itemId)[0];

    rest.defaults.responseType = "blob";
    rest
      .get(`/download_file/${itemId}`)
      .then((response) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(response.data);
        link.setAttribute("download", targetFile.name);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);

        props.showSnackBar("Файл загружен");
        rest.defaults.responseType = "json";
      })
      .catch((error) => {
        props.showSnackBar("Ошибка загрузки файла", "error");
        rest.defaults.responseType = "json";
      });
  }

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setFileOverContainer(true);
    event.dataTransfer.dropEffect = "copy";
  }, []);

  const onDragLeave = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setFileOverContainer(false);
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      const files = event.dataTransfer.files;
      setFileOverContainer(false);

      for (let i = 0; i < files.length; i++) {
        addFileToState(files[i]);
      }
    },
    [addFileToState]
  );

  return (
    <div className={`content-wrapper ${theme}`}>
      <div className="row">
        {showWidgetNav ? (
          <div className="col min vertical-center">
            <CloseIcon
              className="icon small"
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => {
                setShowWidgetNav(!showWidgetNav);
              }}
            />
          </div>
        ) : null}
        <div className="col min vertical-center">
          <h2
            className="h2 active"
            onClick={() => {
              setShowWidgetNav(!showWidgetNav);
            }}
          >
            Файлы
          </h2>
        </div>
        {showWidgetNav ? (
          <div className="col min vertical-center">
            <button
              className="button danger outline small"
              onClick={() => {
                changeWidgetSettingsEvent("showFiles", false);
              }}
            >
              Скрыть раздел
            </button>
          </div>
        ) : (
          <div className="col min vertical-center">
            <input
              type="file"
              style={{ display: "none" }}
              onChange={addFileEvent}
              ref={fileInputRef}
              multiple={true}
            />
            <button
              className={`button small`}
              onClick={() => fileInputRef.current.click()}
            >
              + Добавить
            </button>
          </div>
        )}
      </div>
      {files.length == 0 ? (
        <div className="row">
          <div
            className={
              fileOverContainer
                ? `drop-file-container active`
                : `drop-file-container`
            }
            onDragOver={onDragOver}
            onDrop={onDrop}
            onDragLeave={onDragLeave}
            onClick={() => fileInputRef.current.click()}
          >
            <span>Перетащите файлы сюда</span>
          </div>
        </div>
      ) : null}

      {images.length > 0 ? (
        <div
          className={
            fileOverContainer ? `files-container active` : `files-container`
          }
          onDragOver={onDragOver}
          onDrop={onDrop}
          onDragLeave={onDragLeave}
        >
          <PhotoProvider>
            {images.map((item, index) => (
              <div className="file" key={item.id}>
                <PhotoView key={`img_${item.id}`} src={item.imageUrl}>
                  <img src={item.thumbPath} alt={item.name} />
                </PhotoView>
                <div className="file-body">
                  <b className="text">{item.name}</b>
                  <b className="text grey">
                    {item.daysFromPublication}д. назад
                  </b>
                  <div className="file-nav">
                    <div
                      className={`text dark-blue ${theme}`}
                      onClick={() => deleteFile(item.id)}
                    >
                      Удалить
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </PhotoProvider>
        </div>
      ) : null}
      {files.length > 0 ? (
        <div
          className="files-container"
          onDragOver={onDragOver}
          onDrop={onDrop}
          onDragLeave={onDragLeave}
        >
          {files.map((item) =>
            item.name.match(reImages) ? null : (
              <div className="file" key={item.id}>
                <div
                  className="file-image"
                  style={{ backgroundImage: `url(${item.thumbPath})` }}
                  onClick={() => downloadFile(item.id)}
                />
                <div
                  className="file-body"
                  onClick={() => downloadFile(item.id)}
                >
                  <b className="text bold break-word">{item.name}</b>
                  <b className="text grey">
                    {item.daysFromPublication}д. назад
                  </b>
                </div>
                <div className="file-nav">
                  <div
                    className={`text dark-blue ${theme}`}
                    onClick={() => deleteFile(item.id)}
                  >
                    Удалить
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      ) : null}
    </div>
  );
}
