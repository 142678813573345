import React, { useEffect, useContext, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BasicContext from "../../../Context/BasicContext";
import Column from "./Column";
import AddColumnForm from "./AddColumnForm";
import TaskView from "../../Task/TaskView";
import MinifiedTask from "./MinifiedTask";
import TopMenu from "../TopMenu/TopMenu";
import ProjectViewContext from "../../../Context/ProjectViewContext";
import rest from "../../../http/axios";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import ColumnSettingsModal from "./ColumnModals/ColumnSettingsModal";
import { sendEvent } from "../../../UsefullFunctions";
import NotificationCleanerButton from "./NotificationCleanerButton";

// UI ELEMENTS
import DeskViewSkeleton from "./DeskViewSkeleton";
import Snackbar from "@material-ui/core/Snackbar";
// ICONS
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export default function DeskView(props) {
  const { socket, showSocketAlert, socketAlertInfo, background } =
    useContext(BasicContext);
  const { projectId, taskId } = useParams();
  const projectAreaRef = useRef(null);
  const alertLineRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [stateIsLoaded, setStateIsLoaded] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectPic, setProjectPic] = useState("");
  // const [projectHash, setProjectHash] = useState("");
  const [userRole, setUserRole] = useState("viewer");
  const [columns, setColumns] = useState([]);
  const [taskModalSettings, setTaskModalSettings] = useState({
    addClass: "",
    id: null,
    active: false,
    animate: false,
  });
  const [snackbar, setSnackbar] = useState({
    status: false,
    message: "",
    taskId: 0,
    link: "/web_app",
  });
  const [columnModalSettings, setColumnModalSettings] = useState({
    editableColumn: {},
    active: false,
  });
  const [minifiedTasks, setMinifiedTasks] = useState([]);
  const [totalTasksInProject, setTotalTasksInProject] = useState(0);

  const [columnSeq, setColumnSeq] = useState({}); // пример: {'292': 0, '295': 1, '302: 2}
  const [showTaskModalAfterCreation, setShowTaskModalAfterCreation] =
    useState(false); // открывать ли задача сразу после создания
  // SNAPSHOT
  const [snapshotLoaded, setSnapshotLoaded] = useState(false); // загружен ли снапшот

  const [urlFilter, setUrlFilter] = useState("");
  // Указывает на то, был ли уже подвинут экран при загрузке проекта,
  // Параметр необходим, чтобы не дергать экран каждый раз при открытии карточки
  const [shiftScreenToColumnStatus, setShiftScreenToColumnStatus] =
    useState(false);
  // параметр управляет показом кнопки "Сбросить фильтры"
  // Управляется с этого экрана, потому что испольльзуется в нескольких дочерхних компонентах,
  // а именно TopMenu и AddTaskForm
  const [showUnsetFilterButton, setShowUnsetFilterButton] = useState(false);

  // Активные пользователи в проекте
  const [activeSessions, setActiveSessions] = useState([]);

  // применяется для сокетов, чтобы не обновлять состояние, если оно уже устарело
  // далее есть функция, которая при обновлении columns апдейтит latestColumns
  const latestColumns = useRef(columns);
  const latestActiveSessions = useRef(activeSessions);

  const theme = localStorage.getItem("theme");

  // SCROLL WITH MOUSE WHEEL
  const [wheelIsDown, setwheelIsDown] = useState(false);
  const [offsetLeft, setOffsetLeft] = useState(0); // [offsetLeft, setOffsetLeft
  const lastMouseX = useRef(0);

  // Событие нажали на колесико мыши
  const handleMouseDown = (event) => {
    if (event.button === 1) {
      setwheelIsDown(true);
      lastMouseX.current = event.clientX;

      projectAreaRef.current.style.cursor = "grabbing";
      setOffsetLeft(projectAreaRef.current.scrollLeft);
      event.preventDefault();
    }
  };

  // Событие отпустили колесико мыши
  const handleMouseUp = (event) => {
    if (event.button === 1) {
      setwheelIsDown(false);
      projectAreaRef.current.style.cursor = "grab";
      setOffsetLeft(projectAreaRef.current.scrollLeft);
      event.preventDefault();
    }
  };

  // отрабатываем движение мышки, чтобы скролить доску по зажатому колесику
  const handleMouseMove = (event) => {
    if (wheelIsDown) {
      const deltaX = event.clientX - lastMouseX.current;
      projectAreaRef.current.scrollTo({
        left: offsetLeft - deltaX,
      });
    }
  };

  // running mousedown and mouseup event listeners
  useEffect(() => {
    sendEvent("view", "desk", "view_desk", projectId);

    // Слушаем события нажатия на колесико мыши и отпускания
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);

    // Cleanup function
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  // running mousemove event listener
  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [wheelIsDown]);

  useEffect(() => {
    // при изменении cols обновляю latestColumns, с который умеет работать сокет
    latestColumns.current = columns;

    if (urlFilter == "") {
      if (columns.length > 0) {
        localStorage.setItem(
          `cacheProject_${projectId}`,
          JSON.stringify({
            columns: columns,
            projectName: projectName,
            projectPic: projectPic,
          })
        );
      }
    }

    if (
      shiftScreenToColumnStatus == true ||
      columns.length == 0 ||
      urlFilter == ""
    )
      return;
    shiftScreenToColumn();
    setShiftScreenToColumnStatus(true);
  }, [columns]);

  useEffect(() => {
    // при изменении activeSessions обновляю latestActiveSessions, с который умеет работать сокет
    latestActiveSessions.current = activeSessions;
  }, [activeSessions]);

  // При загрузке экрана загружаю проект
  useEffect(() => {
    latestActiveSessions.current = activeSessions;
    let urlParams = location.search;
    if (urlParams != "") {
      urlParams = urlParams.substr(1);
    }
    // проект загружаем паралельно с сокетом
    loadProject(urlParams);
  }, []);

  // При загрузке экрана пытаюсь открыть задачу, если в адресной строке есть ее id
  useEffect(() => {
    if (stateIsLoaded) {
      if (taskId && taskModalSettings.id === null) {
        showTaskModal(taskId);
      }
    }
  }, [stateIsLoaded]);

  // Уведомляю бэк о подключении и активирую слушатели сокета
  useEffect(() => {
    if (socket) {
      if (socket.connected != true) return;
      if (showSocketAlert == true) return;
      startListeningSocket();
    }
    return () => {
      if (socket) {
        socket.emit("leave_project_room", {
          projectId: projectId,
        });
        console.log("Leaving project room");
        setTimeout(() => {
          console.log("Disabling project listeneres");

          socket.off("update_list_of_project_active_users");
          socket.off("update_list_of_task_active_users");
          socket.off("update_task_name");
          socket.off("update_task_label");
          socket.off("update_task_date_to");
          socket.off("add_subscriber_in_project");
          socket.off("update_user_role_in_task");
          socket.off("update_task_column");
          socket.off("add_task_in_project");
          socket.off("delete_task_from_project");
        }, 500);
      }
    };
  }, [, showSocketAlert]);

  function startListeningSocket() {
    console.log("Start project listeners");
    socket.emit("join_project_room", { projectId: projectId });

    // НУЖНО ДОБАВИТЬ ОБРАБОТКУ МЕТОДОВ
    // adoptLabelProps
    // adoptBudgetlInTask

    // обновляет активных пользователей в задаче
    socket.on("update_list_of_task_active_users", (data) => {
      adoptActiveUserInTask(data);
    });
    // обновляет активных пользователей в проекте
    socket.on("update_list_of_project_active_users", (data) => {
      adoptUserSessions(data);
    });
    socket.on("update_task_name", (data) => {
      if (snapshotLoaded == true) return;
      adoptTaskName(data);
    });
    socket.on("update_task_label", (data) => {
      if (snapshotLoaded == true) return;
      adoptTaskLabel(data);
    });
    socket.on("update_task_date_to", (data) => {
      if (snapshotLoaded == true) return;
      adoptTaskDateTo(data);
    });
    socket.on("add_subscriber_to_task", (data) => {
      if (snapshotLoaded == true) return;
      adoptTaskSubscribers(data);
    });
    socket.on("update_user_role_in_task", (data) => {
      if (snapshotLoaded == true) return;
      adoptTaskRole(data);
    });
    socket.on("update_task_column", (data) => {
      if (snapshotLoaded == true) return;
      adoptTaskColumn(data);
    });
    socket.on("add_task_in_project", (data) => {
      if (snapshotLoaded == true) return;
      if (urlFilter != "") return;
      addTask(data, true);
    });
    socket.on("delete_task_from_project", (data) => {
      if (snapshotLoaded == true) return;
      deleteTask(data);
    });
  }

  const showTaskModal = (id) => {
    if (typeof id === "string") {
      id = parseInt(id);
    }
    if (taskModalSettings.active === true) {
      // Если есть открытое окно, то мы сначала его закрываем, а потом открываем новое
      setTaskModalSettings({ ...taskModalSettings, animate: false });

      setTimeout(() => {
        setTaskModalSettings({
          addClass: "active",
          id: id,
          active: true,
          animate: true,
        });
      }, 500);
    } else {
      setTaskModalSettings({
        addClass: "active",
        id: id,
        active: true,
        animate: true,
      });
    }
    let taskPath = `/web_app/project/${projectId}/task/${id}`;
    navigate(taskPath, { state: { object: "task" } }); // state в этой функции не используется
  };

  const closeTaskModal = () => {
    if (taskModalSettings.id) {
      setTaskModalSettings({
        addClass: "",
        id: null,
        active: false,
        animate: false,
      });
    }
    let path = `/web_app/project/${projectId}`;
    navigate(path, { replace: true });
  };

  function shiftScreenToColumn() {
    const firstColumnWithTasksIndex = columns.findIndex(
      (column) => column.tasks && column.tasks.length > 0
    );
    // Если задач нет, вернется -1, если задача будет в первой колонке, ее индекс будет равен 0
    // и тогда скролить тоже не нужно
    if (firstColumnWithTasksIndex > 0) {
      if (projectAreaRef.current) {
        const firstColumnElement =
          // Вложенность div-ов следующая:
          // project-area -> columnsWrapper -> column
          // поэтому чтобы найти column нужно спуститься на 2 шага вниз
          projectAreaRef.current.children[0].children[
            firstColumnWithTasksIndex
          ];

        if (firstColumnElement) {
          projectAreaRef.current.scrollTo({
            left: firstColumnElement.offsetLeft,
            behavior: "smooth",
          });
        }
      }
    }
  }
  function adoptActiveUserInTask(data) {
    // data = {action: "add", user: {userId: , userName: , userPic: ,}}
    // data = {action: "remove", user
    if (data.action == "add") {
      let newColumns = latestColumns.current.map((col) => {
        col.tasks.map((task) => {
          if (task.id == data.taskId) {
            task.activeSessions = [...task.activeSessions, data.user];
          }
          return task;
        });
        return col;
      });
      setColumns(newColumns);
    } else if (data.action == "remove") {
      let newColumns = latestColumns.current.map((col) => {
        col.tasks.map((task) => {
          if (task.id == data.taskId) {
            task.activeSessions = task.activeSessions.filter(
              (user) => user.userId !== data.user.userId
            );
          }
          return task;
        });
        return col;
      });
      setColumns(newColumns);
    }
  }

  function adoptUserSessions(data) {
    if (data.action == "add") {
      setActiveSessions([...latestActiveSessions.current, data.user]);
    } else if (data.action == "remove") {
      setActiveSessions(
        latestActiveSessions.current.filter(
          (user) => user.userSessionId !== data.user.userSessionId
        )
      );
    }
  }
  const loadProjectFromLocalStorage = () => {
    // Загрузка локальных данных по проекту
    const localProjectData = JSON.parse(
      localStorage.getItem(`cacheProject_${projectId}`)
    );
    // Не загружаю локальный кеш, если его нет
    if (localProjectData == null) return;
    // Не загружаю локальный кеш, если в нем нет activeSessions
    if (
      !localProjectData.columns.some((column) =>
        column.tasks.some((task) => "activeSessions" in task)
      )
    )
      return;

    const localRoles = localStorage.getItem("projectRoles");

    let localProjectRole = "viewer";
    if (localRoles !== null) {
      localProjectRole = JSON.parse(localRoles)[projectId];
    }
    setProjectName(localProjectData.projectName);
    setProjectPic(localProjectData.projectPic);
    setColumns(localProjectData.columns);
    setUserRole(localProjectRole);
  };

  const loadProject = (urlFilter, softLoad = true) => {
    ////////////////// ЗАГРУЖАЕМ ДАННЫЕ ИЗ ЛОКАЛЬНОГО ХРАНИЛИЩА //////////////////
    // В случае softLoad=true данные загружаются из Localstorge, а после загрузки из облака обновляюются в кеше
    if (softLoad == true && urlFilter == "") {
      loadProjectFromLocalStorage();
    }

    ////////////////// ЗАГРУЖАЕМ ДАННЫЕ ИЗ ОБЛАКА //////////////////
    // Записываю filterSettings, которые передаются в Column для динамической pagination
    setUrlFilter(urlFilter);
    setShiftScreenToColumnStatus(false);

    // проверяю фильтры в строке и если они есть, то показываю кнопку "Сбросить фильтры"
    if (urlFilter != "") {
      setShowUnsetFilterButton(true);
    } else {
      setShowUnsetFilterButton(false);
    }

    rest
      .get(`load_project/${projectId}?${urlFilter}`)
      .then((response) => {
        let rsp = response.data;
        setProjectName(rsp["projectName"]);
        setProjectPic(rsp["projectPic"]);
        setUserRole(rsp["userRole"]);
        setColumns(rsp["columns"]);
        if (rsp["activeSessions"].length > 0) {
          setActiveSessions([
            ...latestActiveSessions.current,
            ...rsp["activeSessions"],
          ]);
        }
        setShowTaskModalAfterCreation(rsp["showTaskModalAfterCreation"]);
        setStateIsLoaded(true);
        setTotalTasksInProject(rsp["totalTasksInProject"]);

        // Индексируем массив таким образом, чтобы к колонке был быстрый доступ по ключу
        let newColumnSeq = {};
        rsp.columns.map((element, index) => {
          newColumnSeq[element.column_id.toString()] = index;
        });
        setColumnSeq(newColumnSeq);
      })
      .catch((error) => {
        setProjectName("Нет доступа к проекту");
      });

    setSnapshotLoaded(false);
  };

  const loadProjectFromSnapshot = (snapshot) => {
    setColumns(snapshot);
    setSnapshotLoaded(true);
  };

  const addMinifiedTask = (taskObj) => {
    const search = minifiedTasks.find((task) => task.taskId === taskObj.taskId);
    if (search) {
      return;
    }
    setMinifiedTasks([...minifiedTasks, taskObj]);
  };

  const removeMinifiedTask = (taskId) => {
    setMinifiedTasks(minifiedTasks.filter((task) => task.taskId !== taskId));
  };

  // Очистка нотификаций по в карточке
  const clearNotificationsInTask = (columnId, taskId) => {
    let newColumns = latestColumns.current.map((col) => {
      if (col.column_id == columnId) {
        col.tasks.map((task) => {
          if (task.id == taskId) {
            task.messsageNtfc = 0;
            task.changeNtfc = 0;
          }
          return task;
        });
      }
      return col;
    });
    setColumns(newColumns);
  };

  const adoptColumnName = (columnId, columnName) => {
    let newColumns = latestColumns.current.map((col) => {
      if (col.column_id == columnId) {
        col.name = columnName;
        return col;
      } else {
        return col;
      }
    });
    setColumns(newColumns);
  };

  // Добавляет в колонку динамически загруженную информацию с новыми задачами
  const updateColumnData = (columnId, data) => {
    let newColumns = latestColumns.current.map((col) => {
      if (col.column_id == columnId) {
        col.tasks = [...col.tasks].concat(data);
        return col;
      } else {
        return col;
      }
    });
    setColumns(newColumns);
  };

  const showColumnSettingsModal = (columnId) => {
    if (userRole !== "admin") {
      return;
    }
    var col = columns.filter((col) => col.column_id == columnId)[0];
    setColumnModalSettings({ editableColumn: col, active: true });
  };

  const hideColumnSettingsModal = () => {
    setColumnModalSettings({ editableColumn: {}, active: false });
  };

  const saveColumSettings = (settings) => {
    if (userRole !== "admin") {
      return;
    }

    let newColumns = latestColumns.current.map((col) => {
      if (col.column_id == settings.columnId) {
        col.showDateOfUpdate = settings.showDateOfUpdate;
        col.showDateTo = settings.showDateTo;
        col.sortOrder = settings.sortOrder;
        col.showUsers = settings.showUsers;
      }
      return col;
    });
    setColumns(newColumns);

    var payload = settings;
    payload["projectId"] = projectId;

    rest.post("/update_column_settings", payload).then((response) => {
      let rsp = response.data;
      // после изменения настроек перезагружаю проект, чтобы обновить данные на доске
      let urlParams = location.search;
      if (urlParams != "") {
        urlParams = urlParams.substr(1);
      }
      loadProject(urlParams);
    });
    hideColumnSettingsModal();
  };

  const addColumn = (event) => {
    event.preventDefault();
    if (userRole !== "admin") {
      return;
    }
    // В значениях массива columnSeq хранятся локальные индексы колонок. Вытаскиваем их, чтобы потом найти наибольший
    // пример columnSeq {'292': 0, '295': 1, '302: 2}
    const columnSeqVals = Object.values(columnSeq);
    const nextOrderId = Math.max(...columnSeqVals) + 1;

    rest
      .post(`/add_column`, {
        name: event.target[0].value,
        projectId: projectId,
      })
      .then((response) => {
        let rsp = response.data;

        let newColumns = [
          ...columns,
          {
            column_id: rsp["id"],
            name: event.target[0].value,
            numberOfTasks: 0,
            tasks: [],
            showDateOfUpdate: false,
            showDateTo: true,
            showUsers: true,
            sortOrder: "id_desc",
            order: nextOrderId,
          },
        ];
        setColumns(newColumns);

        // Добавляем новую колонку в последовательность, с которой работает навигация перетаскивания карточек
        setColumnSeq({ ...columnSeq, [rsp["id"]]: nextOrderId });
      });
  };

  const deleteColumn = (columnId) => {
    if (userRole !== "admin") {
      return;
    }
    let newColumns = latestColumns.current.filter(
      (col) => col.column_id != columnId
    );
    setColumns(newColumns);
    const newColumnSeq = { ...columnSeq };
    delete newColumnSeq[columnId.toString()];
    setColumnSeq(newColumnSeq);
  };

  const openSnackbar = (taskId, message) => {
    let link = `/web_app/project/${projectId}/task/${taskId}`;
    setSnackbar({ status: true, message: message, link: link, taskId: taskId });
  };

  const closeSnackbar = () => {
    setSnackbar({ status: false, message: "", link: "", taskId: null });
  };

  const addTask = (
    payload, // {projectId:, columnId: , taskObj: ,}
    dynamic = false
  ) => {
    // при наличии фильтра не добавляю задачу, если на доску наложены фильтры
    if (dynamic == true && urlFilter != "") {
      return;
    }

    let newColumns = latestColumns.current.map((col) => {
      if (col.column_id == payload.columnId) {
        col.tasks = [payload.taskObj, ...col.tasks];
        col.numberOfTasks = col.numberOfTasks + 1;
      }
      return col;
    });
    setColumns(newColumns);
    setTimeout(() => {
      let snackTitle = "Добавлена задача " + payload.taskObj.name;
      openSnackbar(payload.taskObj.id, snackTitle);
    }, 500);
  };

  const deleteTask = (payload) => {
    try {
      let newColumns = latestColumns.current.map((col) => {
        if (col.column_id == payload.columnId) {
          let new_col = col;
          let tasks = [...col.tasks];
          const index = tasks.findIndex((item) => item.id === payload.taskId);
          if (index !== -1) {
            tasks.splice(index, 1);
            new_col.tasks = tasks;
            return new_col;
          }
          col.numberOfTasks = col.numberOfTasks - 1;
          return col;
        } else {
          return col;
        }
      });
      setColumns(newColumns);
    } catch (error) {
      console.log(error);
    }
  };

  const changeUserRole = (newRole) => {
    setUserRole(newRole);
  };

  const onDragEnd = (result) => {
    if (snapshotLoaded == true) {
      return;
    }
    if (userRole !== "admin") {
      return;
    }

    const { destination, source, draggableId, type } = result;
    // Обработка события перетаскивания Карточки
    if (type === "task") {
      if (!destination) {
        return;
      }
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }
      // Движение внутри одной колонки
      if (destination.droppableId === source.droppableId) {
        const destination_column_index = columnSeq[destination.droppableId];
        const column = columns[destination_column_index];
        const draggableItem = column.tasks.filter(
          (el) => el.id === parseInt(draggableId)
        )[0]; //возвращается массив, поэтому берем один элемент
        let newTasks = column.tasks;
        newTasks.splice(source.index, 1);
        newTasks.splice(destination.index, 0, draggableItem);
        let newColumns = latestColumns.current.map((col) => {
          if (col.column_id === parseInt(destination.droppableId)) {
            col.tasks = newTasks;
            return col;
          } else {
            return col;
          }
        });
        setColumns(newColumns);

        let movePayload = {
          projectId: projectId,
          taskId: parseInt(draggableId),
          currentColumnId: parseInt(source.droppableId),
          targetColumnId: parseInt(destination.droppableId),
          // targetColumnName: destination_column.name,
          destinationPosition: destination.index,
        };

        socket.emit("update_task_column", movePayload);

        return;
      } else {
        // Перетаскивание между колонками
        const destination_column_index = columnSeq[destination.droppableId];

        const destination_column = columns[destination_column_index];
        const source_column_index = columnSeq[source.droppableId];
        const source_column = columns[source_column_index];

        // Завернул в try, чтобы в случае, если state.columns не успел обновиться, не было ошибки
        // try {
        const draggableItem = source_column.tasks.filter(
          (el) => el.id === parseInt(draggableId)
        )[0]; //возвращается массив, поэтому берем один элемент
        // У переносимой задачи обнуляем дату последнего изменения колонки
        draggableItem.dateOfUpdate = 0;
        draggableItem.columnSuccessStatus = false;

        // Находим элемент в старой колонке и удаляем
        let oldTasks = source_column.tasks;
        oldTasks.splice(source.index, 1);
        // Добавляем элемент в новую колонку
        let newTasks = destination_column.tasks;
        newTasks.splice(destination.index, 0, draggableItem);

        let columnsNewState = latestColumns.current.map((col) => {
          if (col.column_id === parseInt(destination.droppableId)) {
            col.tasks = newTasks;
            col.numberOfTasks = col.numberOfTasks + 1;
            return col;
          } else if (col.column_id === parseInt(source.droppableId)) {
            col.tasks = oldTasks;
            col.numberOfTasks = col.numberOfTasks - 1;
            return col;
          } else {
            return col;
          }
        });

        let movePayload = {
          projectId: projectId,
          taskId: parseInt(draggableId),
          currentColumnId: parseInt(source.droppableId),
          targetColumnId: parseInt(destination.droppableId),
          // targetColumnName: destination_column.name,
          destinationPosition: destination.index,
        };

        socket.emit("update_task_column", movePayload);
        setColumns(columnsNewState);
        return;
        // } catch (e) {
        //   return;
        // }
      }
    }

    // Обработка события перетаскивания Колонки
    if (type === "column") {
      const myDraggableId = parseInt(draggableId.substr(3)); // переменная, которая из col10 делает 10
      const draggableItem = columns.filter(
        (el) => el.column_id == myDraggableId
      )[0];

      const newColumns = columns;
      newColumns.splice(source.index, 1);
      newColumns.splice(destination.index, 0, draggableItem);

      // Собираем новую последовательность колонок для их корректного индексирования и дальнейшего поиска
      let newColumnSeq = {};
      newColumns.map((col, index) => {
        newColumnSeq[col.column_id.toString()] = index;
      });
      setColumnSeq(newColumnSeq);

      // Новую последовательность колонок отправляем на сервер
      rest.post("/update_column_seq", {
        newColumnSeq: newColumnSeq,
        projectId: projectId,
      });
    }
  };

  // Изменить Название задачи
  const adoptTaskName = (
    payload // {projectId: , columnId: ,  taskId: , taskName: ,}
  ) => {
    let newColumns = latestColumns.current.map((col) => {
      if (col.column_id == payload.columnId) {
        col.tasks.map((task) => {
          if (task.id == payload.taskId) {
            task.name = payload.taskName;
          }
          return task;
        });
      }
      return col;
    });
    setColumns(newColumns);
  };

  // Изменить Дату
  const adoptTaskDateTo = (payload) => {
    let newColumns = latestColumns.current.map((col) => {
      if (col.column_id == payload.columnId) {
        col.tasks.map((task) => {
          if (task.id == payload.taskId) {
            task.daysTo = payload.daysTo;
          }
          return task;
        });
      }
      return col;
    });
    setColumns(newColumns);
  };
  // добавление или удаление пользователей
  const adoptTaskSubscribers = (payload) => {
    if (payload.action == "add") {
      let newColumns = latestColumns.current.map((col) => {
        if (col.column_id == payload.columnId) {
          col.tasks.map((task) => {
            if (task.id == payload.taskId) {
              // let newUser = {}
              task.subscribers = [
                {
                  userId: payload.userId,
                  userPic: payload.userPic,
                  userName: payload.userName,
                  role: "",
                  active: true,
                },
                ...task.subscribers,
              ];
            }
            return task;
          });
        }
        return col;
      });
      setColumns(newColumns);
    } else if (payload.action == "remove") {
      let newColumns = latestColumns.current.map((col) => {
        if (col.column_id == payload.columnId) {
          col.tasks.map((task) => {
            if (task.id == payload.taskId) {
              task.subscribers = task.subscribers.filter(
                (elem) => elem.userId !== payload.userId
              );
            }
            return task;
          });
        }
        return col;
      });
      setColumns(newColumns);
    }
  };

  // обновление топиокв в задаче
  const adoptTaskLabel = (
    payload // {columnId: , taskId: , label: }}
  ) => {
    let newColumns = latestColumns.current.map((col) => {
      if (col.column_id == payload.columnId) {
        col.tasks.map((task) => {
          if (task.id == payload.taskId) {
            task.label = payload.label;
          }
          return task;
        });
      }
      return col;
    });
    setColumns(newColumns);
  };

  // изменение топика во всех задачах в случае его ренейма или удаления
  const adoptLabelProps = (
    payload // {labelId: , newLabel: {id: , name: , color:  }}
  ) => {
    let newColumns = latestColumns.current.map((col) => {
      col.tasks.map((task) => {
        if (task.label.id == payload.labelId) {
          task.label = payload.newLabel;
        }
        return task;
      });
      return col;
    });
    setColumns(newColumns);
  };

  const adoptBudgetlInTask = (columnId, taskId, budget) => {
    let newColumns = latestColumns.current.map((col) => {
      if (col.column_id == columnId) {
        col.tasks.map((task) => {
          if (task.id == taskId) {
            task.budget = budget;
          }
          return task;
        });
      }
      return col;
    });
    setColumns(newColumns);
  };

  const adoptTaskRole = (payload) => {
    let newColumns = latestColumns.current.map((col) => {
      if (col.column_id == payload.columnId) {
        col.tasks.map((task) => {
          if (task.id == payload.taskId) {
            task.subscribers.map((subs) => {
              if (subs.userId == payload.userId) {
                subs.role = payload.role;
              }
            });
          }
          return task;
        });
      }
      return col;
    });
    setColumns(newColumns);
  };

  // Изменить колонку задачи
  const adoptTaskColumn = (payload) => {
    let taskToMove = { id: 0 };
    // Прогоняем первый раз все колонки и находим карточку, которую нужно удалить
    let newColumns = latestColumns.current.map((col) => {
      if (col.column_id == payload.currentColumnId) {
        let tasks = col.tasks.filter((task) => {
          if (task.id == payload.taskId) {
            // нашли карточку
            taskToMove = task;
            taskToMove.dateOfUpdate = 0;
            taskToMove.columnSuccessStatus = false;
          }
          return task.id !== payload.taskId;
        });
        col.tasks = tasks;
        col.numberOfTasks = col.numberOfTasks - 1;
        return col;
      } else {
        return col;
      }
    });

    // если задачу не нашли, прерываем функцию
    if (taskToMove.id == 0) {
      console.log("exiting, card move, cause no card found");
      return;
    }

    // Прогоняем второй раз все колонки и вставляем карточку в нужную колонку
    newColumns = newColumns.map((col) => {
      if (col.column_id == payload.targetColumnId) {
        col.tasks = [taskToMove, ...col.tasks];
        col.numberOfTasks = col.numberOfTasks + 1;
        return col;
      } else {
        return col;
      }
    });
    setColumns(newColumns);
  };

  const adoptTaskColumnSuccessStatusInProject = (payload) => {
    let newColumns = latestColumns.current.map((col) => {
      if (col.column_id == payload.columnId) {
        col.tasks.map((task) => {
          if (task.id == payload.taskId) {
            task.columnSuccessStatus = payload.status;
          }
          return task;
        });
      }
      return col;
    });
    setColumns(newColumns);
  };

  const adoptTaskPin = (payload) => {
    let newColumns = latestColumns.current.map((col) => {
      if (col.column_id == payload.columnId) {
        col.tasks.map((task) => {
          if (task.id == payload.taskId) {
            task.pinnedToMainscreen = payload.pinFlag;
          }
          return task;
        });
      }
      return col;
    });
    setColumns(newColumns);
  };

  return (
    <React.Fragment>
      <ProjectViewContext.Provider
        value={{
          adoptColumnNameInProject: adoptColumnName,
          adoptTaskColumnInProject: adoptTaskColumn,
          adoptTaskNameInProject: adoptTaskName,
          adoptTaskLabelInProject: adoptTaskLabel,
          adoptTaskDateInProject: adoptTaskDateTo,
          adoptTaskSubscribersInProject: adoptTaskSubscribers,
          adoptTaskRoleInProject: adoptTaskRole,
          adoptTaskPinInProject: adoptTaskPin,
          adoptTaskColumnSuccessStatusInProject:
            adoptTaskColumnSuccessStatusInProject,
          adoptLabelPropsEvent: adoptLabelProps,

          adoptBudgetlInTask: adoptBudgetlInTask,

          addTaskToProject: addTask, // добавляет новую задачу в state
          deleteTaskFromProject: deleteTask, // удаляет задачу из проекат
          showTaskModalEvent: showTaskModal, // открывает модальное окно с задачей
          clearNotificationsInTaskEvent: clearNotificationsInTask,
          deleteColumnEvent: deleteColumn,
          changeUserRole: changeUserRole,
          loadProjectFromSnapshot: loadProjectFromSnapshot,
          loadProject: loadProject,

          projectId: projectId,
          taskId: taskModalSettings.id,
          userRole: userRole,
          socket: socket,
          showTaskModalAfterCreationEvent: showTaskModalAfterCreation,
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="columns-area"
            direction="horizontal"
            type="column"
          >
            {(provided) => (
              <div
                className={`absolute-container`}
                id="absolute-container"
                style={{
                  background: `url("${process.env.PUBLIC_URL}/${background}")`,
                }}
              >
                <TopMenu
                  projectId={projectId}
                  projectName={projectName}
                  projectPic={projectPic}
                  showUnsetFilterButton={showUnsetFilterButton}
                  serRole={userRole}
                  loadData={loadProject}
                  userRole={userRole}
                  routeToRedirect={"desk"}
                  activeSessions={activeSessions}
                  theme={theme}
                />
                <div className="project-area" ref={projectAreaRef}>
                  <div
                    className={`columns-wrapper`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {columns.length != 0 ? (
                      <React.Fragment>
                        {columns.map((column, index) => (
                          <Column
                            userRole={userRole}
                            projectId={projectId}
                            index={index}
                            column={column}
                            key={column.column_id}
                            urlFilter={urlFilter}
                            showColumnSettingsModal={showColumnSettingsModal}
                            theme={theme}
                            updateColumnDataEvent={updateColumnData}
                          />
                        ))}
                        <AddColumnForm addColumn={addColumn} theme={theme} />
                      </React.Fragment>
                    ) : (
                      <DeskViewSkeleton theme={theme} />
                    )}

                    {provided.placeholder}
                  </div>
                  <NotificationCleanerButton
                    projectId={projectId}
                    location={location}
                    totalTasksInProject={totalTasksInProject}
                    loadProject={loadProject}
                  />
                </div>
                {minifiedTasks.length != 0 ? (
                  <div className={`minified-tasks-area ${theme}`}>
                    {minifiedTasks.map((task, index) => (
                      <MinifiedTask
                        key={index}
                        taskObj={task}
                        removeMinifiedTaskEvent={removeMinifiedTask}
                        theme={theme}
                      />
                    ))}
                  </div>
                ) : null}

                {showSocketAlert ? (
                  <div className="alert-area non-selectable" ref={alertLineRef}>
                    <span>{socketAlertInfo}</span>

                    <button
                      className="button small black outline"
                      style={{ marginLeft: "5px" }}
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Перезагрузить страницу
                    </button>
                  </div>
                ) : null}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {taskModalSettings.active ? (
          <TaskView
            showTaskModalEvent={showTaskModal}
            closeTaskModalFromStatec={closeTaskModal}
            addMinifiedTaskEvent={addMinifiedTask}
            removeMinifiedTaskEvent={removeMinifiedTask}
            animate={taskModalSettings.animate}
            socket={socket}
          />
        ) : null}

        {columnModalSettings.active ? (
          <ColumnSettingsModal
            column={columnModalSettings.editableColumn}
            hideColumnSettingsModalEvent={hideColumnSettingsModal}
            saveColumSettingsEvent={saveColumSettings}
            showModal={columnModalSettings.active}
          />
        ) : null}

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={snackbar.status}
          autoHideDuration={4000}
          onClose={closeSnackbar}
          message={snackbar.message}
          action={
            <React.Fragment>
              <button
                className=" button grey outline"
                onClick={() => {
                  showTaskModal(snackbar.taskId);
                  closeSnackbar();
                }}
              >
                Открыть
              </button>
              <IconButton
                size="small"
                color="inherit"
                onClick={() => closeSnackbar()}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </ProjectViewContext.Provider>
    </React.Fragment>
  );
}

// class DeskView extends Component {

//     // При закрытии TaskModal отправляем на экран проекта
//     this.props.history.replace(
//       `/web_app/project/${this.projectId}${this.state.searchParams}`
//     );
//   };

// }
