import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import AppContext from "./Context/AppContext";
import BasicContext from "./Context/BasicContext";

// ICONS
import HomeIcon from "@material-ui/icons/Home";
import MessageIcon from "@material-ui/icons/Message";

export default function BottomMenu(props) {
  const { theme } = props;

  const appContext = useContext(AppContext);
  const basicContext = useContext(BasicContext);
  const location = useLocation();

  const [activeIcon, setActiveIcon] = useState("projects");

  useEffect(() => {
    if (location.pathname == "/web_app") {
      setActiveIcon("projects");
    } else if (location.pathname == "/web_app/messages") {
      setActiveIcon("messages");
    } else if (location.pathname == "/web_app/changes") {
      setActiveIcon("changes");
    } else if (location.pathname == "/web_app/profile") {
      setActiveIcon("profile");
    }
  });

  return (
    <div className={`bottom-menu ${theme}`}>
      <Link to={`/web_app`} className={`bottom-menu-nav ${theme}`}>
        {activeIcon == "projects" ? (
          <>
            <HomeIcon className="mobile-icon active" />
            <span className="bottom-menu-text blue">Проекты</span>
          </>
        ) : (
          <>
            <HomeIcon />
            <span className="bottom-menu-text ">Проекты</span>
          </>
        )}
      </Link>

      <Link to={`/web_app/messages`} className={`bottom-menu-nav ${theme}`}>
        {activeIcon == "messages" ? (
          <>
            <MessageIcon className="mobile-icon active" />
            <span className="bottom-menu-text blue">Сообщения</span>
          </>
        ) : (
          <>
            <MessageIcon />
            <span className="bottom-menu-text">Сообщения</span>
          </>
        )}
      </Link>

      <Link to={`/web_app/profile`} className={`bottom-menu-nav ${theme}`}>
        <div
          className="avatar small"
          style={{
            backgroundImage: `url(${appContext.currentUser.user_pic})`,
          }}
        ></div>
        {activeIcon == "profile" ? (
          <span className="bottom-menu-text blue">Профиль</span>
        ) : (
          <span className="bottom-menu-text">Профиль</span>
        )}
      </Link>
    </div>
  );
}
