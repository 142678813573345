import React, { useState, useEffect, useRef, useContext } from "react";
import rest from "../../../http/axios";
import ProjectViewContext from "../../../Context/ProjectViewContext";
import { stripHtml } from "../../../UsefullFunctions";
import { transliterate } from "../../../UsefullFunctions";

// UI
import SendIcon from "@material-ui/icons/Send";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";

export default function SendMessage(props) {
  const {
    taskId,
    messageHelperProps,
    userPic,
    typingProps,
    theme,
    showSendMessageContainer,
    sendMessageEvent,
    sendTypingEventOnServer,
  } = props;

  const { projectId } = useContext(ProjectViewContext);

  const [showTexteditorPlaceholder, setShowTexteditorPlaceholder] =
    useState(true); // Показывать ли placeholder в texteditor
  // Указывает на то, нажата ли клавиша ctrl (используется для hotkey crl+enter)
  const [ctrlHotkey, setCtrlHotkey] = useState(false);
  const [typingTimestamp, setTypingTimestamp] = useState(new Date().getTime());

  const [optionalUserPopover, setOptionalUserPopover] = useState(false); // подсказка для выбора пользователя
  const [optionalUsers, setOptionalUsers] = useState([]); // список загруженных пользователей для подсказки
  const [filteredOptionalUsers, setFilteredOptionalUsers] = useState([]); // отфильтрованный список пользователей

  const texteditorRef = useRef();
  const userFilterhRef = useRef();

  useEffect(() => {
    let element = texteditorRef.current;
    if (messageHelperProps.quoteId > 0) {
      element.focus();
    }
    // если приходят новые props, обновляем текст в input
    if (messageHelperProps.action == "edit") {
      element.innerHTML = messageHelperProps.quoteText;
      setShowTexteditorPlaceholder(false);
      focusOnEndOfText(element);
    }
  }, [messageHelperProps]);

  // регулярно отправляем в MessageView.js данные о том, что пользователь печатает
  const typingMessageEvent = (event) => {
    if (new Date().getTime() - typingTimestamp > 3000) {
      sendTypingEventOnServer();
      setTypingTimestamp(new Date().getTime());
    }

    if (event.target.innerText.trim().length > 0) {
      setShowTexteditorPlaceholder(false);
    } else {
      setShowTexteditorPlaceholder(true);
    }
  };

  function sendMessage() {
    let message = texteditorRef.current.innerHTML;
    if (message.trim().length > 0) {
      sendMessageEvent(message);
      texteditorRef.current.innerText = "";
      setShowTexteditorPlaceholder(true);
    }
  }

  function serveTextareaKeyDown(event) {
    if (event.key == "Control" || event.key == "Meta") {
      setCtrlHotkey(true);
    }
    if (event.key == "Enter" && ctrlHotkey == true) {
      sendMessage();
    }
  }

  function serveTextareaKeyUp(event) {
    if (event.key == "Control" || event.key == "Meta") {
      setCtrlHotkey(false);
    }
  }

  // Проверка на наличие упоминаний пользователей
  const checkingForMentions = (event) => {
    let text = event.target.innerText;

    if (text.endsWith("@")) {
      rest
        .get(`/load_optional_users/${projectId}/${taskId}`)
        .then((response) => {
          setOptionalUsers(response.data);
          setFilteredOptionalUsers(response.data);
        });
      setOptionalUserPopover(true);
    }
  };

  function focusOnEndOfText(element) {
    // Переносим каретку в конец текста (сгенерировано chatgpt)
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false); // Move caret to the end
    selection.removeAllRanges();
    selection.addRange(range);
  }

  function serveUserFilterKeyDown(event) {
    if (event.key == "Escape") {
      setOptionalUserPopover(false);

      let element = texteditorRef.current;
      element.focus();
      focusOnEndOfText(element);
    }
    if (event.key == "Backspace" && userFilterhRef.current.value.length == 0) {
      setOptionalUserPopover(false);
      let element = texteditorRef.current;
      element.focus();
      focusOnEndOfText(element);
    }
  }

  const showOptionalUserPopover = (value) => {
    setOptionalUserPopover(value);
    userFilterhRef.current.focus();
  };

  // при загрузке поповера, делаю автофокус на поле ввода
  useEffect(() => {
    setTimeout(() => {
      if (optionalUserPopover) {
        userFilterhRef.current.focus();
      }
    }, 100);
  }, [optionalUserPopover]);

  function tagUserInMessage(userId, userName) {
    // Закрываем поповер
    setOptionalUserPopover(false);
    let user_link = `<a data-userid=${userId} href="#">@${userName}</a>`;
    console.log(user_link);
    let text = texteditorRef.current.innerHTML;
    // if text ends with @, remove it
    // else if text ends with </div>, then add user_link before </div>
    if (text.endsWith("@")) {
      text = text.substring(0, text.length - 1);
      text += user_link; // добавляем упоминание пользователя
    } else if (text.endsWith("</div>")) {
      text = text.substring(0, text.length - 7);
      text += user_link; // добавляем упоминание пользователя
      text += "</div>"; // добавляем </div> обратно
    }
    texteditorRef.current.innerHTML = text;
    focusOnEndOfText(texteditorRef.current);
  }

  function filterOptionalUsers(event) {
    const searchQuery = event.target.value.toLowerCase();
    const tranlitSeartQuery = transliterate(searchQuery);

    const filteredUsers = optionalUsers.filter((user) => {
      return (
        user.userName.toLowerCase().includes(searchQuery) ||
        user.userName.toLowerCase().includes(tranlitSeartQuery)
      );
    });
    setFilteredOptionalUsers(filteredUsers);
  }

  return (
    // showSendMessageContainer принимает значения true/false
    // используется для скрытия окна отправки сообщения при редактировании описания
    // применяется в <TaskNameView /> и <TaskDescriptionView />
    <div
      className={`send-message-container ${
        showSendMessageContainer ? "" : "hide"
      } ${theme}`}
    >
      {messageHelperProps.action !== "" ? (
        <div className="quote">
          <div className="col min" style={{ marginRight: "10px" }}>
            {messageHelperProps.action == "answer" ? (
              <QuestionAnswerIcon />
            ) : null}
            {messageHelperProps.action == "edit" ? <EditIcon /> : null}
          </div>
          <div className="col dots">
            {messageHelperProps.action == "answer" ? (
              <span>Ответ на:</span>
            ) : null}
            {messageHelperProps.action == "edit" ? (
              <span>Редактирование:</span>
            ) : null}

            <span>{stripHtml(messageHelperProps.quoteText)}</span>
          </div>
          <div
            className="col min"
            style={{
              marginLeft: "10px",
            }}
            onClick={() => {
              props.closeMessageHelper();
              texteditorRef.current.innerText = "";
            }}
          >
            <CloseIcon className="active-icon grey" />
          </div>
        </div>
      ) : null}

      <div className="send-message-wrapper">
        <div className="col min desktop-only">
          <div
            className="message-img"
            style={{ backgroundImage: `url(${userPic})` }}
          />
        </div>
        <div className="col message-input-wrapper">
          {/* Всплывающая подсказка для выбора пользователя по нажаниют на @, Расположена над блоком ввода текста */}
          {optionalUserPopover ? (
            <>
              <div className="helper-container">
                <div className="content-wrapper">
                  <div className="input-wrapper" style={{ margin: "0px" }}>
                    <input
                      type="text"
                      placeholder="Введите имя пользователя"
                      className="input search w-input"
                      ref={userFilterhRef}
                      onChange={(event) => {
                        filterOptionalUsers(event);
                      }}
                      onKeyDown={(event) => serveUserFilterKeyDown(event)}
                    />
                  </div>
                </div>
                <div className="modal-content">
                  {filteredOptionalUsers.map((user) => (
                    <div
                      className="select-item"
                      key={user.id}
                      onClick={() => tagUserInMessage(user.id, user.userName)}
                    >
                      <div className="col min">
                        <div
                          className="avatar"
                          style={{ backgroundImage: `url(${user.userPic})` }}
                        />
                      </div>
                      <div className="col">
                        <span className="text">{user.userName}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* задник background, чтобы закрывать подсказку при клике за ее пределеами */}
              <div
                className="invisible-cover"
                onClick={() => showOptionalUserPopover(false)}
              ></div>
            </>
          ) : null}

          <div className="texteditor-wrapper">
            <div
              contentEditable="true"
              className={`texteditor`}
              onInput={(event) => {
                typingMessageEvent(event);
                checkingForMentions(event);
              }}
              ref={texteditorRef}
              onKeyDown={(event) => serveTextareaKeyDown(event)}
              onKeyUp={(event) => serveTextareaKeyUp(event)}
              onPaste={(event) => {
                event.preventDefault();
                let text = event.clipboardData.getData("text/plain");
                document.execCommand("insertText", false, text);
              }}
            ></div>
            {showTexteditorPlaceholder ? (
              <span className="texteditor-placeholder">
                Написать сообщение...
              </span>
            ) : null}

            <button
              className={`texteditor-button`}
              onClick={() => sendMessage()}
            >
              <SendIcon />
            </button>
          </div>
          {typingProps.show == true ? (
            <div className="typing-container">
              <i className="text small blue">{typingProps.name} печатает...</i>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
