import React, { useState, useRef, useEffect } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

// ICONS
import DeleteIcon from "@material-ui/icons/Delete";

export default function ListElement(props) {
  const { item, toggleCheckEvent } = props;
  const inputRef = useRef();

  // const [checked, setChecked] = useState(item.check);
  const [editable, setEditable] = useState(item.editable);
  const [inputVal, setInputVal] = useState(item.desc);

  let stopTriggering = false; // используется для блокировки triggerStaticMode

  function triggerDelete() {
    stopTriggering = true;
    props.deleteElement(item.id);
  }

  useEffect(() => {
    if (editable) {
      inputRef.current.focus();
    }
  }, [editable]);

  function triggerEditMode() {
    setEditable(true);
  }

  function triggerStaticMode() {
    setTimeout(function () {
      if (stopTriggering === false) {
        setEditable(false);
        props.updateDesc(item.id, inputVal);
      }
    }, 200);
  }

  function serveKeyDown(event) {
    if (event.key == "Enter") {
      event.preventDefault();
      props.addElement();
    }
  }

  return (
    <React.Fragment>
      <div className="col min vertical-center">
        <Checkbox
          color="primary"
          checked={item.check}
          onChange={(event) => {
            toggleCheckEvent(item.id, event.target.checked);
          }}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>
      {editable ? (
        <div className="col">
          <div className="input-wrapper" style={{ margin: "0px" }}>
            <input
              type="text"
              ref={inputRef}
              className={`w-input input`}
              style={{ paddingRight: "30px" }}
              inputProps={{ autoFocus: true }}
              onBlur={() => triggerStaticMode()}
              value={inputVal}
              multiline
              maxRows={2}
              onChange={(event) => {
                setInputVal(event.target.value);
              }}
              onKeyDown={(event) => serveKeyDown(event)}
            />
            <DeleteIcon
              className="input-icon-wrapper active"
              onClick={() => triggerDelete()}
              style={{ fontSize: "1.5em" }}
            />
          </div>
        </div>
      ) : (
        <div className="col vertical-center" onClick={() => triggerEditMode()}>
          <span className="text">{item.desc}</span>
        </div>
      )}
    </React.Fragment>
  );
}
