import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../../Context/AppContext";
import ProjectViewContext from "../../../Context/ProjectViewContext";
import BasicContext from "../../../Context/BasicContext";
import { useNavigate } from "react-router-dom";
import { transliterate } from "../../../UsefullFunctions";
import rest from "../../../http/axios";

// ELEMENTS
import Avatar from "@material-ui/core/Avatar";
import SearchIcon from "@material-ui/icons/Search";

//UI
import Slide from "@material-ui/core/Slide";

// ICONS
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SvgIcon from "@material-ui/core/SvgIcon";
import StarIcon from "@material-ui/icons/Star";
// import VisibilityIcon from "@material-ui/icons/Visibility";
import BuildIcon from "@material-ui/icons/Build";
import BlurOnIcon from "@material-ui/icons/BlurOn";

// SIGNALS
import { subscribers } from "../TaskView";

export default function Subscribers(props) {
  const { updateSubscribersList, updateUserRole, showSnackBar } = props;
  const {
    projectId,
    taskId,
    adoptTaskRoleInProject,
    adoptTaskSubscribersInProject,
  } = useContext(ProjectViewContext);
  const { currentUser } = useContext(AppContext);
  const { socket } = useContext(BasicContext);

  const modalRef = useRef();
  const searchRef = useRef();
  const navigate = useNavigate();

  const [optionalUsers, setOptionalUsers] = useState([]); // [{id: int, userName: 'str', userPic: 'str', addedStatus: boolean}
  const [filteredOptionalUsers, setFilteredOptionalUsers] = useState([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showUserDetailModal, setShowUserDetailModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedUserDetails, setSelectedUserDetails] = useState({
    userName: "",
    userId: 0,
    email: "",
    userPic: "",
    role: "",
  });
  const [roles, setRoles] = useState([
    {
      id: "role_1",
      name: "Делаю задачку",
      value: "doSomething",
      selected: "",
      icon: "M22.7 19l-9.1-9.1c.9-2.3.4-5-1.5-6.9-2-2-5-2.4-7.4-1.3L9 6 6 9 1.6 4.7C.4 7.1.9 10.1 2.9 12.1c1.9 1.9 4.6 2.4 6.9 1.5l9.1 9.1c.4.4 1 .4 1.4 0l2.3-2.3c.5-.4.5-1.1.1-1.4z",

      color: "#79da75",
    },
    {
      id: "role_2",
      name: "Очень жду",
      value: "waitSomething",
      selected: "",
      icon: "M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z",
      color: "#4dafe0",
    },
    {
      id: "role_3",
      name: "Просто смотрю",
      value: "",
      selected: "",
      icon: "M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z",
      color: "#bdbdbd",
    },
  ]);

  const openAddUserModal = () => {
    rest.get(`load_optional_users/${projectId}/${taskId}`).then((response) => {
      let resp = response.data;
      setOptionalUsers(resp);
      setFilteredOptionalUsers(resp);
      setShowAddUserModal(true);
      setTimeout(() => searchRef.current.focus(), 100);
    });
  };

  useEffect(() => {
    if (modalRef.current === undefined) return;
    const height = modalRef.current.clientHeight;
    modalRef.current.style.minHeight = height + "px";
  }, [modalRef]);

  const closeAddUserModal = () => setShowAddUserModal(false);

  const openUserDetailModal = (userId) => {
    let userDetails = subscribers.value.filter(
      (user) => user.userId === userId
    )[0];
    setShowUserDetailModal(true);
    setSelectedUserDetails(userDetails);
    setSelectedRole(userDetails.role);
    setRoles(
      roles.map((item) => ({
        ...item,
        selected: item.value === userDetails.role ? "selected" : "",
      }))
    );
  };

  const closeUserDetailModal = () => setShowUserDetailModal(false);

  const selectRole = (event) => {
    setSelectedRole(event.target.value);
    // setRoles(
    //   roles.map((item) => ({
    //     ...item,
    //     selected: item.id === event.target.id ? "selected" : "",
    //   }))
    // );

    setRoles(
      roles.map((item) => {
        if (item.id === event.target.id) {
          item.selected = "selected";
        } else {
          item.selected = "";
        }
        return item;
      })
    );
  };

  const saveRole = () => {
    const roleDeliveredByDefault =
      selectedUserDetails.userId === currentUser.id ? true : false;

    let roleData = {
      projectId: projectId,
      columnId: props.columnId,
      taskId: taskId,
      userId: selectedUserDetails.userId,
      role: selectedRole,
      roleDelivered: false, // ставлю всегда false, чтобы смотреть анимацию, когда назначаю себе роль
    };
    socket.emit("update_user_role", roleData);
    updateUserRole(roleData);
    adoptTaskRoleInProject(roleData);

    closeUserDetailModal();
  };

  const addSubscriber = (event) => {
    event.preventDefault();
    const optionalUserId = event.target[0].value;
    const optionalUserPic = event.target[1].value;
    const optionalUserName = event.target[2].value;

    const userData = {
      projectId: projectId,
      taskId: taskId,
      columnId: props.columnId,
      userId: optionalUserId,
      userPic: optionalUserPic,
      userName: optionalUserName,
      action: "add",
    };

    socket.emit("add_subscriber", userData);
    updateSubscribersList(userData);
    adoptTaskSubscribersInProject(userData);

    setFilteredOptionalUsers(
      filteredOptionalUsers.map((item) => {
        if (item.id === parseInt(optionalUserId)) {
          return { ...item, addedStatus: true };
        }
        return item;
      })
    );
  };

  const removeSubscriber = (userId) => {
    const userData = {
      projectId: projectId,
      taskId: taskId,
      columnId: props.columnId,
      userId: userId,
      action: "remove",
    };

    if (subscribers.value.length === 1) {
      showSnackBar("В задаче должен остаться хотя бы один участник", "error");
      return;
    }

    updateSubscribersList(userData);
    adoptTaskSubscribersInProject(userData);
    closeUserDetailModal();
    socket.emit("remove_subscriber", userData);
  };

  const filterOptionalUsers = (event) => {
    const baseSearch = event.target.value.toLowerCase();
    const translitSearch = transliterate(baseSearch); // Assuming transliterate is a utility function

    if (baseSearch !== "") {
      setFilteredOptionalUsers(
        optionalUsers.filter(
          (item) =>
            item.userName.toLowerCase().includes(baseSearch) ||
            item.userName.toLowerCase().includes(translitSearch)
        )
      );
    } else {
      setFilteredOptionalUsers([...optionalUsers]);
    }
  };

  return (
    <div className="modal-navigation">
      {subscribers.value.length > 0
        ? subscribers.value.map((user, index) => {
            let animateIcon = "";
            if (user.role === "waitSomething") {
              animateIcon =
                user.roleDelivered == false && currentUser.id == user.userId
                  ? "animation-blue"
                  : "";
            }
            if (user.role === "doSomething") {
              animateIcon =
                user.roleDelivered == false && currentUser.id == user.userId
                  ? "animation-green"
                  : "";
            }
            return (
              <div className="col min" key={index}>
                <div
                  className="avatar big"
                  style={{ backgroundImage: `url(${user.userPic})` }}
                  onClick={() => openUserDetailModal(user.userId)}
                >
                  {user.role === "waitSomething" ? (
                    <div
                      className={
                        user.roleDelivered
                          ? `avatar-icon blue`
                          : `avatar-icon ${animateIcon}`
                      }
                    >
                      <StarIcon className="blue" style={{ fontSize: 14 }} />
                    </div>
                  ) : null}

                  {user.role === "doSomething" ? (
                    <React.Fragment>
                      <div
                        className={
                          user.roleDelivered
                            ? `avatar-icon green`
                            : `avatar-icon ${animateIcon}`
                        }
                      >
                        <BuildIcon className="green" style={{ fontSize: 14 }} />
                      </div>
                    </React.Fragment>
                  ) : null}
                  {user.active === false ? (
                    <div className="avatar-blackout">
                      <BlurOnIcon />
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })
        : null}
      <div className="round-button" onClick={openAddUserModal}>
        <PersonAddIcon style={{ fontSize: "1.3em" }} />
      </div>
      {/* МОДАЛЬНОЕ ОКНО */}
      {showAddUserModal ? (
        <React.Fragment>
          <div className="modal-cover active" onClick={closeAddUserModal}></div>
          <Slide
            direction="up"
            in={showAddUserModal}
            mountOnEnter
            unmountOnExit
          >
            <div className="modal-wrapper">
              <div className="simple-modal" ref={modalRef}>
                <div className="modal-header">
                  <h1 className="h1">Добавить в задачу</h1>
                </div>
                <div className="content-wrapper">
                  <div className="input-wrapper" style={{ margin: "0" }}>
                    <input
                      type="text"
                      ref={searchRef}
                      className="input search w-input"
                      placeholder="Поиск по команде"
                      onChange={(event) => {
                        filterOptionalUsers(event);
                      }}
                    />
                    <div className="input-icon-wrapper">
                      <SearchIcon />
                    </div>
                  </div>
                </div>
                <div className="modal-content">
                  {filteredOptionalUsers.map((item) => (
                    <form
                      className={`select-item`}
                      key={item.id}
                      onSubmit={addSubscriber}
                    >
                      <div
                        className="col avatar big min"
                        style={{
                          backgroundImage: `url(${item.userPic})`,
                        }}
                        // key={currentUser.id}
                      />
                      <div className="col w-form-label">
                        <span className="text">{item.userName}</span>
                      </div>
                      <input type="hidden" value={item.id} />
                      <input type="hidden" value={item.userPic} />
                      <input type="hidden" value={item.userName} />
                      {item.addedStatus ? (
                        <div className=" button done outline small">
                          Добавлено
                        </div>
                      ) : (
                        <div className="col min">
                          <button type="submit" className="button  small">
                            + Добавить
                          </button>
                        </div>
                      )}
                    </form>
                  ))}
                  {filteredOptionalUsers.length === 0 ? (
                    <>
                      <span className="text small">
                        Мы не нашли пользователя, которого вы искали, в этом
                        проекте. Вы можете приглашить его в проект в разделе
                        Настроек:
                      </span>

                      <button
                        className="button small warning outline"
                        style={{ display: "inline-block" }}
                        onClick={() =>
                          navigate(`/web_app/project/${projectId}/settings`)
                        }
                      >
                        Настройки проекта
                      </button>
                    </>
                  ) : null}
                </div>
                <div className="modal-footer">
                  <button className=" button" onClick={closeAddUserModal}>
                    Закрыть
                  </button>
                </div>
              </div>
            </div>
          </Slide>
        </React.Fragment>
      ) : null}
      {showUserDetailModal ? (
        <React.Fragment>
          <div
            className="modal-cover active"
            onClick={closeUserDetailModal}
          ></div>
          <div className="modal-wrapper">
            <div className="simple-modal">
              <div className="modal-header">
                <h1 className="h1">Профиль</h1>
              </div>
              <div className="modal-messages">
                <div className="row">
                  <div className="col">
                    <Avatar
                      style={{ width: 100, height: 100 }}
                      src={selectedUserDetails.userPic}
                    />
                  </div>
                  <div className="col">
                    <h1 className="h1">{selectedUserDetails.userName}</h1>
                    <div className="button-wrapper left">
                      <button
                        className="button outline danger small"
                        onClick={() => {
                          removeSubscriber(selectedUserDetails.userId);
                        }}
                      >
                        Удалить из задачи
                      </button>
                    </div>
                    {/* <span className="text">kosteyz@mail.ru</span> */}
                  </div>
                </div>
                <div
                  className="content-wrapper"
                  onChange={(event) => selectRole(event)}
                >
                  <h2 className="h2">Роль в проекте:</h2>

                  {roles.map((role) => (
                    <label className={`select-item ${role.selected}`}>
                      <input
                        type="radio"
                        id={role.id}
                        name="selectRole"
                        className="hidden-radio"
                        value={role.value}
                      />

                      <SvgIcon style={{ color: `${role.color}` }}>
                        <path d={role.icon} />
                      </SvgIcon>

                      <span className="text">&emsp; {role.name}</span>
                    </label>
                  ))}
                </div>
              </div>
              <div className="modal-footer">
                <button className=" button" onClick={closeUserDetailModal}>
                  Закрыть
                </button>
                <button className=" button primary" onClick={() => saveRole()}>
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
}
