import React, { useRef, useState, useEffect, useContext } from "react";
import { MdMarkEmailRead } from "react-icons/md";
import rest from "../../../http/axios";

const NotificationCleanerButton = ({
  location,
  totalTasksInProject,
  projectId,
  loadProject,
}) => {
  const animationContainerRef = useRef(null);

  const [showNotificationCleaner, setShowNotificationCleaner] = useState(false);

  useEffect(() => {
    console.log("totalTasksInProject: ", totalTasksInProject);
    if (location.search.includes("active=true") && totalTasksInProject > 0) {
      setShowNotificationCleaner(true);
    } else {
      setShowNotificationCleaner(false);
    }
  }, [totalTasksInProject]);

  const handleClick = () => {
    rest.post("/clear_all_notifications_on_desk", { projectId: projectId });
    runBurnAnimation();
  };

  const runBurnAnimation = () => {
    if (totalTasksInProject == 0) return;

    document.getElementById("animation-container").style.display = "block";

    for (let i = 0; i < totalTasksInProject; i++) {
      const maxWidth = animationContainerRef.current.offsetWidth;
      const xPos = Math.random() * maxWidth;
      const animationDelay = Math.random() * 700;
      const fireEmoji = document.createElement("div");

      fireEmoji.className = "letter-emoji";
      fireEmoji.innerText = "✉️";
      fireEmoji.style.left = `${xPos}px`;
      fireEmoji.addEventListener("animationend", () => {
        fireEmoji.remove();
      });

      document.getElementById("animation-container").appendChild(fireEmoji);

      setTimeout(() => {
        fireEmoji.style.animation = `flyUp 500ms linear`;
        fireEmoji.style.opacity = "1";
      }, animationDelay);
    }
    setTimeout(() => {
      loadProject("");
      location.search = "";
    }, 1000);
  };

  return (
    <>
      {showNotificationCleaner ? (
        <div className="notification-cleaner">
          <div
            className="notification-cleaner-animation-container"
            id="animation-container"
          ></div>
          <button
            className="button "
            style={{ margin: "0px" }}
            onClick={() => handleClick()}
          >
            <MdMarkEmailRead /> &nbsp; Отметить всё прочитанным
          </button>
          <div
            className="animation-container"
            ref={animationContainerRef}
          ></div>
        </div>
      ) : null}
    </>
  );
};

export default NotificationCleanerButton;
