import React from "react";
import parse, { domToReact } from "html-react-parser";

// Функция для очистки строки от HTML тегов
export const parseHtml = (html) => {
  const result = parse(html);
  return result;
};

// Функция для очистки строки от HTML тегов, меняем <div> <p> <li> на \n и удаляем все остальные теги
// делаю это без parse
export const stripHtml = (html) => {
  const result = html
    .replace(/<(div|p|li)>/gi, "\n")
    .replace("&nbsp;", " ")
    .replace(/<[^>]*>/g, "")
    .trim();
  return result;
};

// Функция для отправки событий в Google Analytics
export const sendEvent = (action, category, label, value) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

export const transliterate = (text) => {
  var engToRusMap = {
    а: "a",
    б: "b",
    в: "v",
    г: "g",
    д: "d",
    е: "e",
    ё: "yo",
    ж: "zh",
    з: "z",
    и: "i",
    й: "y",
    к: "k",
    л: "l",
    м: "m",
    н: "n",
    о: "o",
    п: "p",
    р: "r",
    с: "s",
    т: "t",
    у: "u",
    ф: "f",
    х: "kh",
    ц: "ts",
    ч: "ch",
    ш: "sh",
    щ: "shch",
    ъ: "",
    ы: "y",
    ь: "",
    э: "e",
    ю: "yu",
    я: "ya",
    a: "а",
    b: "б",
    c: "к",
    d: "д",
    e: "е",
    f: "ф",
    g: "г",
    h: "х",
    i: "и",
    j: "дж",
    k: "к",
    l: "л",
    m: "м",
    n: "н",
    o: "о",
    p: "п",
    q: "к",
    r: "р",
    s: "с",
    t: "т",
    u: "у",
    v: "в",
    w: "в",
    x: "кс",
    y: "й",
    z: "з",
  };

  return text.replace(/[а-яёa-z]/g, function (char) {
    return engToRusMap[char] || char;
  });
};
