import React, { useEffect, useState, useContext } from "react";
import rest from "../../../http/axios";
import AddLinkedTask from "./AddLinkedTask";
import ProjectViewContext from "../../../Context/ProjectViewContext";
import BasicContext from "../../../Context/BasicContext";

//ICONS
import BlurOnIcon from "@material-ui/icons/BlurOn";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import UpdateIcon from "@material-ui/icons/Update";
import { MdJoinLeft } from "react-icons/md";

export default function LinkedTasksView(props) {
  const {
    projectId,
    taskId,
    theme,
    changeWidgetSettingsEvent,
    showTaskModalEvent,
    showSnackBarEvent,
  } = props;

  const { userRole } = useContext(ProjectViewContext);
  const { socket } = useContext(BasicContext);

  const [linkedTasks, setLinkedTasks] = useState([]);
  const [showWidgetNav, setShowWidgetNav] = useState(false); //
  const [showModal, setShowModal] = useState(false); // Отображает модальное окно для добавления задачи
  const [scrolled, setScrolled] = useState(false); // Состояние для определения был ли скролл

  const [ctrlHotkey, setCtrlHotkey] = useState(false);

  useEffect(() => {
    rest.get(`/load_task_links/${taskId}`).then((response) => {
      const rsp = response.data;
      setLinkedTasks(rsp.linkedTasks);
    });
  }, []);

  // включаем слушатель кнопок ctrl и meta
  useEffect(() => {
    function serveKeyDown(event) {
      if (event.key == "Control" || event.key == "Meta") {
        setCtrlHotkey(true);
      }
    }

    function serveKeyUp(event) {
      if (event.key == "Control" || event.key == "Meta") {
        setCtrlHotkey(false);
      }
    }
    window.addEventListener("keydown", serveKeyDown);
    window.addEventListener("keyup", serveKeyUp);

    return () => {
      window.removeEventListener("keydown", serveKeyDown);
      window.removeEventListener("keyup", serveKeyUp);
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("link_task_to_task", (data) => {
        console.log("Socket data: ", data);
        addLinkedTaskToTaskState(data);
      });
    }
  }, [linkedTasks]);

  const addLinkedTaskToTaskState = (data) => {
    let newLinkedTask = data.taskObj;
    newLinkedTask.projectId = data.projectId;
    newLinkedTask.columnId = data.columnId;
    newLinkedTask.columnName = data.columnName;

    setLinkedTasks([newLinkedTask, ...linkedTasks]);
  };

  const removeLinkedTask = (removedTaskId) => {
    setLinkedTasks(linkedTasks.filter((item) => item.id !== removedTaskId));
    rest
      .post("/remove_link_between_tasks", {
        firstTaskId: taskId,
        secondTaskId: removedTaskId,
      })
      .then((response) => {});
  };

  return (
    <div className={`modal-content-container ${theme}`}>
      <div className="row">
        {showWidgetNav ? (
          <div className="col min vertical-center">
            <CloseIcon
              className="icon small"
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => {
                setShowWidgetNav(!showWidgetNav);
              }}
            />
          </div>
        ) : null}
        <div className="col min">
          <h2
            className="h2 active"
            onClick={() => {
              if (userRole == "admin") {
                setShowWidgetNav(!showWidgetNav);
              }
            }}
          >
            Связанные задачи
          </h2>
        </div>
        {userRole == "admin" ? (
          <React.Fragment>
            {showWidgetNav ? (
              <div className="col min vertical-center">
                <button
                  className="button danger outline small"
                  onClick={() => {
                    changeWidgetSettingsEvent("showLinks", false);
                  }}
                >
                  Скрыть раздел
                </button>
              </div>
            ) : (
              <div className="col vertical-center min">
                <button
                  className={`button small`}
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Добавить
                </button>
              </div>
            )}
          </React.Fragment>
        ) : null}
      </div>
      <div className={`modal-ref-container`}>
        {linkedTasks.map((task) => (
          <div style={{ position: "relative" }} key={task.id}>
            <div
              onClick={() => {
                if (projectId == task.projectId) {
                  if (ctrlHotkey) {
                    let url =
                      window.location.origin +
                      `/web_app/project/${task.projectId}/task/${task.id}`;
                    window.open(url, "_blank");
                  } else {
                    showTaskModalEvent(task.id);
                  }
                } else {
                  let url =
                    window.location.origin +
                    `/web_app/project/${task.projectId}/task/${task.id}`;
                  window.open(url, "_blank");
                }
              }}
              onLongClick={() => setShowWidgetNav(!showWidgetNav)}
              className={`task horizontal`}
              key={task.id}
            >
              <div className="col col-5 vertical-center">
                {task.label.id ? (
                  <span className={`label ${task.label.color}`}>
                    {task.label.name}
                  </span>
                ) : null}
                <span className={`text medium break-word`}>{task.name}</span>

                {projectId != task.projectId ? (
                  <span
                    className="text small grey"
                    style={{ marginTop: "10px" }}
                  >
                    Из внешнего проекта
                  </span>
                ) : null}

                {task.initTaskId == taskId ? (
                  <div className="text small blue icon-centered">
                    <span
                      className="icon big blue"
                      style={{ marginRight: "3px" }}
                    >
                      <MdJoinLeft />
                    </span>
                    Клон текущей задачи
                  </div>
                ) : null}
              </div>
              <div className="col col-5 vertical-center">
                <div className="row vertical-for-mobile">
                  <div
                    className={`col col-5 vertical-center full-screen-on-mobile`}
                  >
                    <span className={`task-column-label ${theme}`}>
                      {task.columnName}
                    </span>
                    <ul role="list" className="footer-info">
                      {/* Уведомления об истечении срока */}

                      <li className="footer-info-item">
                        <UpdateIcon style={{ fontSize: "1.2em" }} />
                        &#160; {task.dateOfUpdate} д.
                      </li>
                    </ul>
                  </div>

                  <div className="col col-5 vertical-center hidden-on-mobile">
                    <div className="task-footer-container">
                      {task.subscribers.map((user, index) => (
                        <div
                          key={index}
                          className="avatar"
                          style={{
                            backgroundImage: `url(${user.userPic})`,
                          }}
                        >
                          {user.role == "doSomething" ? (
                            <div className="avatar-icon min green" />
                          ) : null}
                          {user.role == "waitSomething" ? (
                            <div className="avatar-icon min blue" />
                          ) : null}
                          {user.active == false ? (
                            <div className="avatar-blackout">
                              <BlurOnIcon />
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showWidgetNav ? (
              <div className="remove-cover">
                <RemoveCircleOutlineIcon
                  className="active"
                  onClick={() => removeLinkedTask(task.id)}
                />
              </div>
            ) : null}
          </div>
        ))}
      </div>

      {/* МОДАЛЬНОЕ ОКНО */}
      {showModal ? (
        <AddLinkedTask
          parentTaskId={taskId}
          setShowModalEvent={setShowModal}
          showSnackBarEvent={showSnackBarEvent}
          addLinkedTaskToTaskState={addLinkedTaskToTaskState}
        />
      ) : null}
    </div>
  );
}
