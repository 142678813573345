import React, { useState, useEffect, useContext } from "react";
import AddProject from "./AddProject";
import ProjectListCard from "./ProjectListCard";
import TopMenu from "./TopMenu";
import { useNavigate, NavLink } from "react-router-dom";
import AppContext from "../Context/AppContext";
import BasicContext from "../Context/BasicContext";
import rest from "../http/axios";
import ProjectListSkeleton from "./ProjectListSkeleton";
import { sendEvent } from "../UsefullFunctions";

// UI
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const defaultSnackbar = {
  status: false,
  message: "",
  link: "/web_app",
  severity: "warning",
};

function SnackbarButton() {
  const navigate = useNavigate();

  function handleClick() {
    navigate("/web_app/profile");
  }
  return (
    <button className="button warning" onClick={handleClick}>
      Перейти
    </button>
  );
}

export default function ProjectListView(props) {
  const { currentUser } = useContext(AppContext);
  const { background } = useContext(BasicContext);

  const [projectItems, setProjectItems] = useState([]);
  const [filteredProjectItems, setFilteredProjectItems] = useState([]);
  const [loadSuccessfull, setLoadSuccessfull] = useState(false);
  const [snackbar, setSnackbar] = useState(defaultSnackbar);

  const theme = localStorage.getItem("theme");
  const navigate = useNavigate();

  useEffect(() => {
    sendEvent("view", "project_list", "view_project_list", 1);
    // Загружаем проект
    loadProjects();

    // показываем уведомление о небходимости смены имени
    try {
      if (currentUser.default_name == currentUser.name) {
        openSnackbar(
          "/web_app",
          "Для простоты коммуникации с вами укажите имя"
        );
      }
    } catch (e) {}
  }, []);

  useEffect(() => {
    // Включаем таймер по проверке уведомлений
    let notificationsInterval = setInterval(loadAllNotifications, 10000);

    return () => {
      clearInterval(notificationsInterval);
    };
  }, [loadSuccessfull]);

  function loadProjects() {
    // Загружаю кеш списка проектов из локалстораджа
    let localProjectData = [];
    let localProjectElement = localStorage.getItem("cacheProjectList");

    if (localProjectElement !== null) {
      localProjectData = JSON.parse(localProjectElement);
    }

    setProjectItems(localProjectData);
    setFilteredProjectItems(localProjectData);

    rest
      .get(`/load_project_list`)
      .then((response) => {
        setProjectItems(response.data);
        setFilteredProjectItems(response.data);
        setLoadSuccessfull(true);

        let projectRoles = {};
        response.data.map((item) => {
          projectRoles[item.id] = item.projectRole;
        });
        // Добавляем в Localstorage информацию о клиентских доступах
        localStorage.setItem("projectRoles", JSON.stringify(projectRoles));
        localStorage.setItem("cacheProjectList", JSON.stringify(response.data));
        console.log("Записываем актуальный список в LocalStorage");
      })
      .catch((e) => {
        console.log("Произошла ошибка при загрузке прокта ", e);
      });
  }

  function loadAllNotifications() {
    if (projectItems.length == 0) return;
    rest.get("/load_notifications_for_project_list").then((response) => {
      const rsp = response.data.allNotifications;
      let newProjectItems = projectItems.map((item) => {
        if (rsp[item.id]) {
          item.changesNtfc = rsp[item.id].changeNtfc;
          item.msgNtfc = rsp[item.id].messageNtfc;
        }
        return item;
      });
      setProjectItems(newProjectItems);
    });
  }

  function addNewProject(name) {
    let projectName = name.trim();
    if (projectName === "") return;

    rest
      .post(`/add_project`, {
        name: projectName,
      })
      .then((response) => {
        navigate(`/web_app/project/${response.data.id}`);
      });
  }

  function filterProjects(event) {
    let searchInput = event.target.value.toLowerCase();
    if (searchInput !== "") {
      let searchData = projectItems.filter((item) => {
        if (item.name.toLowerCase().includes(searchInput)) {
          return item;
        }
      });
      setFilteredProjectItems(searchData);
    } else {
      setFilteredProjectItems(projectItems);
    }
  }

  const acceptInvite = (projectId) => {
    rest
      .post(`/accept_project_invite`, {
        projectId: projectId,
      })
      .then((response) => {
        let newProjectList = projectItems.map((item) => {
          if (item.id === projectId) {
            item.inviteAccepted = true;
          }
          return item;
        });
        setProjectItems(newProjectList);
        setFilteredProjectItems(newProjectList);
      });
  };

  const declineInvite = (projectId) => {
    rest
      .post(`/decline_project_invite`, {
        projectId: projectId,
      })
      .then((response) => {
        let newProjectList = projectItems.filter((item) => {
          if (item.id !== projectId) {
            return item;
          }
        });

        setProjectItems(newProjectList);
        setFilteredProjectItems(newProjectList);
      });
  };

  function openSnackbar(link, message, severity = "warning") {
    setSnackbar({
      status: true,
      message: message,
      link: link,
      severity: severity,
    });
  }

  function closeSnackbar() {
    setSnackbar(defaultSnackbar);
  }

  return (
    <React.Fragment>
      <TopMenu filterProjects={filterProjects} theme={theme} />

      <div
        className={`content-container`}
        style={{
          background: `url("${process.env.PUBLIC_URL}/${background}")`,
        }}
      >
        {loadSuccessfull || projectItems.length > 0 ? (
          <React.Fragment>
            {projectItems.length === 0 ? (
              <div className="row">
                <div className="col">
                  <span className="super-title">
                    Привет, {currentUser.name}!
                  </span>

                  <p className="text">
                    Для начала работы с Branzy создайте новый проект
                  </p>
                  {currentUser.default_name == currentUser.name ? (
                    <p className="text">
                      Чтобы изменить имя, прейдите в{" "}
                      <NavLink to="/web_app/profile">профиль</NavLink>
                    </p>
                  ) : null}
                </div>
              </div>
            ) : null}

            <div className={`projects-container`}>
              {projectItems.length !== 0
                ? filteredProjectItems.map((item, index) => (
                    <ProjectListCard
                      item={item}
                      key={item.id}
                      theme={theme}
                      acceptInviteEvent={acceptInvite}
                      declineInviteEvent={declineInvite}
                    />
                  ))
                : null}
              <AddProject
                className=", button, active"
                addNewProject={addNewProject}
                theme={theme}
              />
            </div>
          </React.Fragment>
        ) : (
          <ProjectListSkeleton theme={theme} />
        )}
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbar["status"]}
        autoHideDuration={5000}
        onClose={closeSnackbar}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={snackbar["severity"]}
          onClose={closeSnackbar}
          action={<SnackbarButton />}
        >
          {snackbar["message"]}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
